import React from "react"
import Layout from "../layouts"
import Seo from "../components/seo"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  BreadCrumb,
  Container,
  Section,
  MarkdownBody,
  MarkdownContent,
} from "../components/Section"
import {
  HeroContainer,
  Hero,
  Background,
  HeroCaption,
  HeroTitle,
  // HeroDescription,
} from "../components/Hero"
import BreakpointUp from "../components/Media/BreakpointUp"

const SitemapGrid = styled.div`
  box-sizing: border-box;
  column-gap: 5px;
  position: relative;
  column-count: 1;
  ${BreakpointUp.sm`
        column-count: 2;
    `}
  ${BreakpointUp.lg`
        column-count: 3;
    `}
`

const SitemapItem = styled.div`
  margin-bottom: 30px;
  & a {
    display: inline-block;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.7);
    &:hover {
      color: #000;
    }
  }
  & ul {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    @media (min-width: 1600px) {
      font-size: 16px;
      line-height: 26px;
    }
    & li {
      margin: 0;
    }
  }
`

const SiteMapTitle = styled.div`
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  margin: 0 0 10px;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
  @media (min-width: 1600px) {
    font-size: 32px;
    line-height: 42px;
  }
  & a {
    color: #000;
    &:hover {
      color: #fc0002;
    }
  }
`

const StaticPage = ({ location }) => (
  <Layout isHeader={true} location={location}>
    <Seo title="Sitemap" description="Sitemap" />
    <Section
      xpt="114px"
      mpt="94px"
      pt="64px"
      xpb="0"
      mpb="0"
      pb="0"
      bgColor="transparent"
    >
      <Container maxWidth="100%" pl="3%" pr="3%">
        <HeroContainer>
          <Hero>
            <Background>
              <StaticImage
                src="../images/banner-static-bg.jpg"
                placeholder="blurred"
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="banner-static-bg"
              />
            </Background>
            <HeroCaption>
              <BreadCrumb className="white" position="static" translate="0">
                <Link to="/">Home</Link>
                <span>Sitemap</span>
              </BreadCrumb>
              <HeroTitle>Sitemap</HeroTitle>
            </HeroCaption>
          </Hero>
        </HeroContainer>
      </Container>
    </Section>
    <Section pt="0" xpb="60px" mpb="40px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <MarkdownBody>
          <MarkdownContent maxWidth="1496px">
            <SitemapGrid>
              <SitemapItem>
                <SiteMapTitle>
                  <Link to="/metal-buildings">Metal Buildings</Link>
                </SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/commercial-metal-buildings">
                      Commercial Buildings
                    </Link>
                  </li>
                  <li>
                    <Link to="/clear-span-buildings">Clear Span Buildings</Link>
                  </li>
                  <li>
                    <Link to="/custom-metal-buildings">
                      Custom Metal Buildings
                    </Link>
                  </li>
                  <li>
                    <Link to="/metal-workshops">Metal Workshops</Link>
                  </li>
                  <li>
                    <Link to="/metal-warehouses">Metal Warehouses</Link>
                  </li>
                </ul>
              </SitemapItem>
              <SitemapItem>
                <SiteMapTitle>
                  <Link to="/metal-barns">Metal Barns</Link>
                </SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/continuous-roof-barns">
                      Continuous Roof Barns
                    </Link>
                  </li>
                  <li>
                    <Link to="/a-frame-vertical-roof-barns">
                      A-Frame Vertical Roof Barns
                    </Link>
                  </li>
                  <li>
                    <Link to="/a-frame-horizontal-roof-barns">
                      A-Frame Horizontal Roof Barns
                    </Link>
                  </li>
                  <li>
                    <Link to="/regular-roof-barns">Regular Roof Barns</Link>
                  </li>
                </ul>
              </SitemapItem>
              <SitemapItem>
                <SiteMapTitle>
                  <Link to="/metal-garages">Metal Garages</Link>
                </SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/a-frame-vertical-roof-garages">
                      A-Frame Vertical Roof Garages
                    </Link>
                  </li>
                  <li>
                    <Link to="/a-frame-horizontal-roof-garages">
                      A-Frame Horizontal Roof Garages
                    </Link>
                  </li>
                  <li>
                    <Link to="/regular-roof-garages">Regular Roof Garages</Link>
                  </li>
                </ul>
              </SitemapItem>
              <SitemapItem>
                <SiteMapTitle>
                  <Link to="/metal-carports">Metal Carports</Link>
                </SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/a-frame-vertical-roof-carports">
                      A-Frame Vertical Roof Carports
                    </Link>
                  </li>
                  <li>
                    <Link to="/a-frame-horizontal-roof-carports">
                      A-Frame Horizontal Roof Carports
                    </Link>
                  </li>
                  <li>
                    <Link to="/regular-roof-carports">
                      Regular Roof Carports
                    </Link>
                  </li>
                </ul>
              </SitemapItem>
              <SitemapItem>
                <SiteMapTitle>Resources</SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/rent-to-own">Rent-To-Own</Link>
                  </li>
                  <li>
                    <Link to="/financing">Financing</Link>
                  </li>
                  <li>
                    <Link to="/service-area">Service Area</Link>
                  </li>
                  <li>
                    <Link to="/color-options">Color Options</Link>
                  </li>
                  <li>
                    <Link to="/faq">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/steel-building-components">
                      Steel Building Components
                    </Link>
                  </li>
                  <li>
                    <Link to="/metal-building-roof-guide">
                      Metal Building Roof Guide
                    </Link>
                  </li>
                  <li>
                    <Link to="/site-preparation-guide">
                      Site Preparation Guide
                    </Link>
                  </li>
                  <li>
                    <Link to="/order-installation-process">
                      Order/Installation Process
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                </ul>
              </SitemapItem>
              <SitemapItem>
                <SiteMapTitle>Liberty info</SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/contact-us">Contact us</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About LMS</Link>
                  </li>
                  <li>
                    <Link to="/why-choose-us">Why Choose Us</Link>
                  </li>
                </ul>
              </SitemapItem>
              <SitemapItem>
                <SiteMapTitle>Other Links</SiteMapTitle>
                <ul>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-of-use">Terms of Use </Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                </ul>
              </SitemapItem>
            </SitemapGrid>
          </MarkdownContent>
        </MarkdownBody>
      </Container>
    </Section>
  </Layout>
)

export default StaticPage
